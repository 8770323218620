@import "../../../../resource/style/variables";

.assetWrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 3440px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 2560px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1920px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(133px, 1fr));
    grid-gap: 16px;
  }
}

.filterOpen {
  max-width: calc(100vw - 32px);

  .assetWrapper {
    @media (max-width: calc(3440px + 340px)) {
      grid-template-columns: repeat(8, 1fr);
    }

    @media (max-width: calc(2560px + 340px)) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: calc(1920px + 340px)) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: calc(1600px + 340px)) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: calc(1200px + 340px)) {
      grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    }

    @media (max-width: calc(1023px + 340px)) {
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    @media (max-width: calc(767px + 340px)) {
      grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    }
  }
}

.spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.05);
  box-shadow: 0 0 0 10px rgba(0,0,0,.05);
  display: flex;
  justify-content: center;
  color: #000;

  > * {
    top: 35vh;
  }
}
