@import "src/resource/style/variables";

.row {
  display: grid;
  grid-template-columns: minmax(600px, 1fr) 1.6fr;

  @media (max-width: 1600px) {
    grid-template-columns: minmax(500px, 1fr) 1.5fr;
  }

  @media (max-width: 1439px) {
    grid-template-columns: minmax(400px, 1fr) 1.4fr;
  }

  @media (max-width: 1023px) {
    grid-template-columns: minmax(300px, 1fr) 1.25fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: minmax(200px, 1fr) 1.25fr;
  }

  &:not(:last-child) {
    border-bottom: solid 1px var(--color-line);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  > :first-child {
    font-weight: 500;

    > svg {
      margin-left: 8px;
      color: $color-grey-scale-CL12;
      font-size: var(--font-sizebody1);
    }
  }

  &.rowTree {
    grid-template-columns: 28.5% 71.5%;
  }

  .row:last-child {
    border-bottom: none;
  }

  > *:first-child {
    text-transform: capitalize;
  }

  > * {
    overflow: hidden;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 40px;
    padding: 9px 16px;

    > *:not(svg) {
      width: 100%;
      overflow: auto;
    }
  }

  a {
    border: none !important;
    box-shadow: none !important;
    display: inline-flex;
    align-items: center;

    svg {
      font-size: var(--font-sizeheading6);
      margin-right: 8px;
    }
  }
}

.rowContent {
  .twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr !important;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 420px) {
      grid-template-columns: 1fr !important;
    }
  }

  .openAll {
    margin: 4px 0;
    padding: 4px 0;
    cursor: pointer;
    color: $primary-color-CL17;
  }
}
