.content {
  max-width: 1920px;

  .wrp {
    max-width: 800px;

    a {
      color: #000;
    }

    h1 {
      font-size: var(--font-sizeheading1);
      line-height: var(--line-heightheading1);
      font-weight: 700;
      text-transform: uppercase;
      margin: 24px 0;
    }

    .data {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 36px;

      > * {
        width: 100%;
      }
    }

    .sections {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .section {
        border-left: solid 1px var(--color-line);
        padding-left: 16px;
        max-width: 256px;

        @media (max-width: 600px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .text {
      font-size: var(--font-sizebody1);
      line-height: var(--line-heightbody1);

      b {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
  }
}
