@import "src/resource/style/variables";

.selectedFilters {
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr auto;
  gap: 16px;
  margin-bottom: 16px;

  .list {
    display: block;
    margin-bottom: -16px;

    .item {
      position: relative;
      display: inline-block;
      height: 40px;
      min-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 40px 0 8px;
      background-color: #00000014;
      transition: .3s;
      cursor: default;
      margin-bottom: 8px;
      line-height: 40px;

      @media (max-width: 600px) {
        height: 36px;
        line-height: 36px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &:hover {
        background-color: #00000025;
      }

      .remove {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        display: flex;
        width: 36px;
        height: 40px;
        justify-content: center;
        align-items: center;
        transition: .3s;

        @media (max-width: 600px) {
          height: 36px;
          line-height: 36px;
        }

        &:hover {
          background-color: #00000015;
        }

        svg {
          font-size: var(--font-sizeheading6);
          color: #000;

          @media (max-width: 600px) {
            font-size: var(--font-sizebody1);
          }
        }
      }
    }


    @media (max-width: 1023px) {
      margin-bottom: 0;
      white-space: nowrap;
      overflow-x: auto;
      padding: 0 0 6px 0;

      .item {
        margin-bottom: 0;
      }
    }

    @media (max-width: 600px) {
      padding: 0 0 2px 0;
    }
  }

  .clear {
    @media (max-width: 600px) {
      height: 36px;
      line-height: 36px;
      padding: 0 14px;
    }
  }

  .clear:first-child {
    display: none;
  }

  @media (max-width: 600px) {
    grid-template-columns: auto 1fr;

    .list {
      margin-right: -16px;

      > *:last-child {
        margin-right: 8px;
      }
    }

    .clear:first-child {
      display: block;
    }

    .clear:last-child {
      display: none;
    }
  }
}
