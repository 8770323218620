@import "src/resource/style/variables";

@media (max-width: 1245px) {
  .hide {
    display: none !important;
  }
}

.result {
  color: $color-grey-scale-CL12;

  @media (max-width: 600px) {
    line-height: 24px !important;
    min-height: 24px !important;
  }
}

.filter {
  .form {
    width: calc(100% - 32px);

    @media (max-width: 400px) {
      width: calc(100% - 16px);
    }
  }

  .result {
    font-size: var(--font-sizebody2);
    color: #707070;
    padding: 16px 0 0 16px;

    @media (min-width: 626px) {
      display: none;
    }

    @media (max-width: 400px) {
      padding: 16px 0 0 8px;
    }
  }
}

.wrp {
  padding: 0 0 40px;
  width: 100%;
  position: relative;
}

:global {
  .filterOpen {
    display: flex;
    gap: 24px;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media (max-width: 675px) {
      gap: 16px;
    }

    @media (max-width: 625px) {
      &:before {
        z-index: 1;
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        pointer-events: none;
      }

      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        left: -16px;
        top: -16px;
        width: calc(100% + 32px);
        height: 16px;
        background-color: #fff;
        pointer-events: none;
      }
    }
  }
}

.select,
.filter {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: var(--font-sizebody1);
  box-shadow: inset 0 0 0 1px #ccc;

  @media (max-width: 675px) {
    min-width: auto;
    max-width: 400px;
    width: 400px;
  }

  @media (max-width: 600px) {
    max-width: none;
    width: 100%;
  }

  svg {
    font-size: var(--font-sizeheading6);
    color: #000;
  }
}

.filter .select {
  width: 100%;
  max-width: none;
  min-width: auto;
}

.filter {
  flex-wrap: wrap;
  display: none;
  align-content: flex-start;
  align-items: flex-start;
  z-index: 1;

  @media (max-width: 720px) {
    position: absolute;
    box-shadow: inset 0 0 0 1px #ccc, 0 3px 12px 0 #00000033;
    background-color: #fff;
  }

  @media (max-width: 625px) {
    position: absolute;
    box-shadow: none;
    width: calc(100% + 16px * 2);
    left: -16px;
    top: -1px;
    right: 0;
    max-width: none;
    padding: 0 16px;
  }

  .select {
    box-shadow: none;
  }

  .search {
    margin: 16px;

    @media (max-width: 400px) {
      margin: 16px 8px;
    }
  }

  .row {
    width: 100%;
    margin-bottom: 8px;
    padding: 0 24px;

    @media (max-width: 400px) {
      padding: 0 16px;
    }

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-weight: 500;

      &.disabled {
        cursor: default;
        opacity: .4;
      }
    }

    .list {
      transition: .3s;
      overflow: hidden;

      &:not(.open) {
        height: 0 !important;
      }

      .scroll {
        max-height: calc(40px * 6);
        overflow-x: hidden;
        overflow-y: auto;
        margin: 8px 0;
      }

      .search {
        margin: 8px 0;

        > * {
          margin: 0;
        }
      }

      .option {
        min-height: 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        padding: 7px 0;
        box-sizing: border-box;

        > * {
          display: flex;
          align-items: start;
          font-size: var(--font-sizebody1);

          > span {
            padding-inline-end: 0;
          }
        }
      }
    }
  }
}

.select {
  position: relative;
  cursor: pointer;

  .reset {
    font-size: var(--font-sizebody2);
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    color: $primary-color-CL17;
    font-weight: 500;
  }

  svg {
    margin: 10px 18px 10px 26px;
  }
}

.filterOpen {
  .filter {
    display: flex;
  }
}

.header {
  width: 100%;
  grid-gap: 16px;
  display: grid;
  border-bottom: solid 1px var(--color-line);
  grid-template-columns: auto 1fr 1.2fr auto;
  margin-bottom: 16px;
  padding: 0 0 16px 0;
  position: relative;
  min-height: 40px;
  line-height: 40px;
  box-sizing: content-box;

  @media (max-width: 3800px) {
    grid-template-columns: auto 1fr 1.3fr auto;
  }

  @media (max-width: 3000px) {
    grid-template-columns: auto 1fr 1.45fr auto;
  }

  @media (max-width: 2120px) {
    grid-template-columns: auto 1fr 1.6fr auto;
  }

  @media (max-width: 1700px) {
    grid-template-columns: auto 1fr 1.9fr auto;
  }

  @media (max-width: 1350px) {
    grid-template-columns: auto 1fr 2.5fr auto;
  }

  .tabletShow {
    display: none;
    justify-content: center;
  }

  &:not(.filterOpen) {
    @media (max-width: 1245px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .tabletHide {
        display: none;
      }

      .tabletShow {
        width: 100%;
        display: flex;
      }
    }

    .mobileShow {
      display: none;
      justify-content: center;
    }

    @media (max-width: 795px) {
      .mobileHide {
        display: none;
      }

      .mobileShow {
        width: 100%;
        display: flex;
      }
    }

    @media (max-width: 675px) {
      justify-content: center;
    }
  }

  &.filterOpen {
    grid-template-columns: 1fr 2fr auto;

    @media (max-width: 1245px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .tabletHide {
        display: none;
      }

      .tabletShow {
        width: 100%;
        display: flex;

        @media (max-width: 720px) {
          justify-content: flex-end;
        }
      }
    }

    .mobileShow {
      display: none;
      justify-content: center;

      @media (max-width: 720px) {
        justify-content: flex-end;
      }
    }

    @media (max-width: 805px) {
      .mobileHide {
        display: none;
      }

      .mobileShow {
        width: 100%;
        display: flex;
      }
    }

    @media (max-width: 805px) {
      justify-content: center;
    }

    @media (max-width: 720px) {
      justify-content: flex-end;
    }
  }

  > * {
    line-height: 40px;
    min-height: 40px;
  }

  .options {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    max-width: 310px;
    width: 100%;

    @media (max-width: 675px) {
      max-width: 400px;
    }

    @media (max-width: 600px) {
      max-width: none;
      width: 100%;
    }

    .perpage {
      > * {
        width: 100%;
      }
    }

    .order {
      > * {
        min-width: 205px;
        max-width: 220px;
        width: 100%;

        @media (max-width: 675px) {
          max-width: none;
        }
      }
    }
  }
}
