@import 'src/resource/style/variables';

.layout {
  max-width: 100%;
  min-height: 100vh;
  background-color: $bg-color-content;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    padding: 0;
    border: none !important;
    z-index: 9;

    &:focus {
      box-shadow: none !important;
    }

    .logoSvg {
      width: 100%;
      max-width: 146px;
      height: 20px;
      object-fit: contain;
    }

    &.collapsed {
      .logoSvg {
        height: 28px;
      }
    }
  }

  .filter {
    height: calc(100% - 72px);
    overflow: hidden;

    &.collapsed {
      display: none;
    }
  }

  .slider {
    position: relative;
    z-index: 3;
    height: 100vh;
    min-height: 100vh;
    background-color: $color-white-CL01;
    box-shadow: inset -1px 0px 0px #edf0f3;

    &.absolute {
      position: absolute;
    }

    .btn {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      z-index: 10;
      width: 36px;
      height: 36px;
      background: $color-white-CL01;
      border-radius: 0;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
      transition: 0.1s;

      &.collapsed {
        top: 72px;
        right: 6px;
        box-shadow: none;
      }
    }
  }
}

.header {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  box-shadow: 0 3px 12px 0 #00000033;
  z-index: 2;

  @media (max-width: 1023px) {
    box-shadow: none;
    height: 108px;
  }

  .wrp {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: flex-start;
    position: relative;
    max-width: 1920px;
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: var(--font-sizeheading6);
    line-height: var(--line-heightheading6);
    font-weight: 400;
    padding: 0 9px;

    &.titleTablet {
      display: none;
      justify-content: center;
      width: 100%;
    }

    @media (max-width: 1023px) {
      width: 100%;

      &.titleDesktop {
        display: none;
      }

      &.titleTablet {
        display: flex;
      }
    }
  }

  &.headerLogo .wrp {
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: auto auto 1fr;

    @media (max-width: 1023px) {
      grid-template-columns: auto 1fr;
    }

    .logo {
      padding: 0 24px;
      box-sizing: border-box;
      width: 194px;
      border-right: solid 1px #edf0f3;

      &.tablet {
        width: 164px;
      }

      &.phone {
        width: 132px;
        padding: 0 0 0 16px;
      }
    }
  }
}

.wrapper {
  position: relative;
  background-color: $bg-color-content;

  &.tablet {
    margin-left: 48px;
  }

  .scroll {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 72px);
    overflow: auto;

    @media (max-width: 1023px) {
      max-height: none;
      overflow: visible;
    }

    .content {
      position: relative;
      box-sizing: border-box;
      min-height: calc(100vh - 72px - 24px * 2 - 157px);
      max-width: calc(100vw - 32px);
      margin: 24px 24px 64px 24px;
      background: $color-white-CL01;
      border-radius: 8px;

      @media (max-width: 1023px) {
        min-height: calc(100vh - 108px - 24px * 2 - 157px);
      }

      @media (max-width: 767px) {
        min-height: calc(100vh - 108px - 16px * 2 - 290px);
        margin: 16px 16px 64px 16px;
      }

      &.transparent {
        background: transparent;
      }

      &.minWidth {
        min-width: 920px;
      }
    }

    :global {
      .center {
        display: flex;
        justify-content: center;

        > * {
          width: 100%;
        }
      }
    }
  }
}

.footer {
  position: relative;
  display: block;
  border-top: solid 1px var(--color-line);
  width: 100%;

  > *:not(.moveToTop) {
    max-width: 1920px;
    width: 100%;
  }
}
