@import "../../../../../resource/style/variables";

.modalShare {
  .content {
    padding: 8px 0 24px;

    .info {
      .success {
        display: flex;
        align-items: center;
        background-color: #0088001A;
        height: 52px;
        color: $color-green-CL20;
        margin-bottom: 12px;

        .icon {
          margin-right: 16px;
          width: 40px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-green-CL20;

          svg {
            font-size: var(--font-sizeheading6);
            color: #fff;
          }
        }
      }

      .linkWrp {
        color: $color-grey-scale-CL13;
        margin-bottom: 12px;
      }

      .expires {
        color: $color-grey-scale-CL13;
      }
    }
  }
}

.actions {
  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    > * {
      width: 100%;
      margin: 0 !important;
    }
  }
}
