@import "src/resource/style/variables";

.info {
  overflow: hidden;

  .title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;

    .type {
      font-size: var(--font-sizelabel1);
      line-height: 16px;
      font-weight: 400;
      color: $color-grey-scale-CL12;
    }
  }

  .rights {
    font-size: var(--font-sizeheading6);
    line-height: var(--line-heightheading6);
    font-weight: 400;
    color: $color-grey-scale-CL12;

    .usage {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      padding: 10px 0;

      @media (max-width: 870px) {
        gap: 16px;
      }

      .note {
        font-size: var(--font-sizelabel1);
        height: 20px;
        line-height: 20px;
        border: none;
      }
    }
  }

  > * {
    margin: 24px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 626px) {
      margin: 16px 0;
    }
  }

  .action {
    display: flex;
    gap: 24px;
    margin: 24px 0;
    flex-wrap: wrap;

    @media (max-width: 870px) {
      gap: 16px;
    }

    @media (max-width: 626px) {
      margin: 16px 0;
    }

    @media (max-width: 600px) {
      > * {
        width: 100%;
      }
    }
  }

  .line {
    border-bottom: solid 1px var(--color-line);
  }

  .description {
    .name {
      font-size: var(--font-sizeheading6);
      line-height: var(--line-heightheading6);
      font-weight: 400;
      color: $color-grey-scale-CL12;
      margin-bottom: 8px;
    }

    .text {
      font-size: var(--font-sizebody1);
      line-height: 24px;
      font-weight: 400;
    }
  }
}
