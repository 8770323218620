$primary-color-CL17: #376EB4;
$primary-color-CL18: #0A5A96;
$primary-color-CL19: #004673;

// Grey Scale
$color-grey-scale-CL01: #FFFFFF;
$color-white-CL01: $color-grey-scale-CL01;
$color-grey-scale-CL02: #FCFCFC;
$color-grey-scale-CL03: #F7F7F7;
$color-grey-scale-CL04: #F5F5F5;
$color-grey-scale-CL05: #F1F1F1;
$color-grey-scale-CL06: #E6E6E6;
$color-grey-scale-CL07: #E3E3E3;
$color-grey-scale-CL08: #D9D9D9;
$color-grey-scale-CL09: #CCCCCC;
$color-grey-scale-CL10: #B4B4B4;
$color-grey-scale-CL11: #999999;
$color-grey-scale-CL12: #707070;
$color-grey-scale-CL13: #666666;
$color-grey-scale-CL14: #5C5C5C;
$color-grey-scale-CL15: #3D3D3D;
$color-grey-scale-CL16: #000000;
$color-black-CL16: $color-grey-scale-CL16;

// Signal Colors
$color-signal-CL20: #008800;
$color-green-CL20: $color-signal-CL20;

$color-signal-CL21: #F07800;
$color-yellow-CL21: $color-signal-CL21;

$color-signal-CL22: #CE0000;
$color-red-CL20: $color-signal-CL22;

// Accent Colors
$color-accent-colors_CL19: #004673;
$color-dark-blue_CL19: $color-accent-colors_CL19;

$color-accent-colors_CL21: #F07800;
$color-orange_CL21: $color-accent-colors_CL21;

$color-accent-colors_CL23: #377B78;
$color-dark-green-colors_CL23: $color-accent-colors_CL23;

// Other
$neutral-secondary: #8C8C8C;
$bg-color-content: #fff;
$border-color: #D9D9D9;

:root {
  --font-sizeheading1: 42px;
  --line-heightheading1: 52px;

  --font-sizeheading2: 32px;
  --line-heightheading2: 40px;

  --font-sizeheading3: 28px;
  --line-heightheading3: 36px;

  --font-sizeheading4: 26px;
  --line-heightheading4: 32px;

  --font-sizeheading5: 24px;
  --line-heightheading5: 32px;

  --font-sizeheading6: 20px;
  --line-heightheading6: 28px;


  --font-sizelabel1: 13px;
  --line-heightlabel1: 16px;

  --font-sizelabel2: 12px;
  --line-heightlabel2: 16px;

  --color-line: #00000029;

  --font-sizebody2: 14px;
  --line-heightbody2: 20px;

  --font-sizebody1: 16px;
  --line-heightbody1: 24px;
}
