@import 'src/resource/style/variables';

.lang {
  width: 100%;
  justify-content: flex-start !important;
  text-transform: capitalize;

  svg {
    margin: 12px;
  }
}
