@import 'src/resource/style/variables';

.content {
  max-width: 1920px;

  .header {
    padding: 24px 16px;
    border-bottom: solid 1px var(--color-line);

    @media (max-width: 600px) {
      padding: 24px 0;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    }

    .title {
      text-transform: uppercase;
      font-size: 46px;
      line-height: 46px;
      min-height: 60px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
        flex-wrap: wrap;
        gap: 16px;
        margin: 7px 0;
      }

      @media (max-width: 600px) {
        padding-top: 0;
        font-size: 36px;
        line-height: 36px;
      }
    }

    .counter {
      font-weight: 400;
      line-height: 28px;
      font-size: var(--font-sizelabel1);
      color: $color-grey-scale-CL12;

      @media (max-width: 600px) {
        margin: -16px 0 16px;
      }
    }
  }

  .wrp {
    padding: 1px;
    position: relative;
    width: 100%;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    padding: 24px 0 8px;

    @media (max-width: 1600px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 600px) {
      padding: 16px 0 8px;
    }
  }

  .pagination {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tabletAndMore {
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
}

.onlyMobile {
  width: 100%;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
}
