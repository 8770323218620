@import "src/resource/style/variables";
@import "src/resource/font/stylesheet";

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  appearance: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #727272;
  border-radius: 4px !important;
}

::-webkit-scrollbar-track {
  background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.rc-virtual-list-scrollbar {
  width: 5px !important;
  background-color: #e5e5e5;

  .rc-virtual-list-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #727272 !important;
  }
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

html, body {
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--fontglobal), sans-serif;
  background-color: #fff;
}

#root {
  background-color: #fff;
  min-height: 100vh;
  min-width: 315px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .ant-app {
    width: 100%;
  }
}

img {
  margin: 0;
  padding: 0;
  display: block;
}

a {
  border: solid 1px rgba(0,0,0,0);
  border-bottom: solid 1px $primary-color-CL17;

  &:hover {
    border-bottom-color: $primary-color-CL18;
  }

  &:active {
    border-bottom-color: $primary-color-CL19;
  }

  &[disabled],
  &.ant-a-disable,
  &.ant-link-disable {
    border-bottom-color: rgba(0,0,0,0.26);
  }

  //&:visited {
  //  color: #666666;
  //  border-bottom-color: #666666;
  //}

  &:focus {
    border-color: $primary-color-CL17 !important;
    box-shadow: inset 0 0 0 1px $primary-color-CL17 !important;
  }
}

.ant-input {
  &:not(:focus) {
    box-shadow: none !important;
  }

  &:hover {
    border-color: #5C5C5C;
  }

  &:focus {
    border-color: $primary-color-CL17;
    box-shadow: inset 0 0 0 1px $primary-color-CL17;
  }

  &.ant-input-disabled,
  &[disabled] {
    border-color: #F7F7F7;
    background-color: #F7F7F7;
  }

  &.ant-input-lg {
    padding: 9px 8px;
    font-size: var(--font-sizebody2);
    line-height: 20px;
  }
}

.ant-select-dropdown {
  padding: 0;

  .ant-select-item {
    &:first-child {
      padding-top: 18px;
    }

    &:last-child {
      padding-bottom: 18px;
    }

    @media (max-height: 450px) {
      padding: 0 8px !important;
      display: flex;
      align-items: center;
      height: 36px;
    }

    line-height: 20px;
    background-color: transparent !important;
    color: $color-black-CL16;
    font-weight: 500;

    &:hover {
      color: $primary-color-CL17;
    }
  }
}

.ant-select {
  .ant-select-selector {
    border-color: $color-grey-scale-CL10 !important;
  }

  &:hover .ant-select-selector {
    border-color: $color-grey-scale-CL14 !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border-color: $color-black-CL16 !important;
      box-shadow: none !important;
    }
  }

  .ant-select-arrow {
    svg {
      font-size: var(--font-sizeheading6);
      width: 20px;
      height: 20px;
      color: #000;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      svg {
        rotate: 180deg;
      }
    }
  }
}

.ant-btn {
  box-shadow: none !important;

  &:focus-visible {
    box-shadow: none !important;
  }

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  &.ant-btn-default {
    &[disabled],
    &.ant-btn-disabled {
      background-color: rgba(0,0,0,0);
      border-color: rgba(0,0,0,0.26);
    }
  }

  &:not(.ant-btn-disabled):not([disabled]) {
    &.ant-btn-default {
      border-color: #000000;

      &:hover {
        background-color: rgba(0,0,0,0.08);
        border-color: #000;
        color: #000;
      }

      &:focus {
        border-color: $primary-color-CL17;
        box-shadow: inset 0 0 0 1px $primary-color-CL17;
      }

      &:active {
        background-color: #3D3D3D;
        border-color: #3D3D3D;
        box-shadow: none;
        color: $color-white-CL01;
      }
    }

    &.ant-btn-primary {
      color: $color-white-CL01;

      &:hover {
        background-color: $primary-color-CL18;
        border-color: $primary-color-CL18;
      }

      &:focus {
        border-color: $primary-color-CL19;
        box-shadow: inset 0 0 0 1px $primary-color-CL19;
      }

      &:active {
        background-color: $primary-color-CL19;
        border-color: #004673;
        box-shadow: none;
      }
    }

    &.ant-btn-text {
      color: #000;

      &:hover {
        background-color: rgba(0,0,0,0.08);
      }

      &:focus {
        border-color: $primary-color-CL17;
        box-shadow: inset 0 0 0 1px $primary-color-CL17;
      }

      &:active {
        background-color: #3D3D3D;
        border-color: #3D3D3D;
        box-shadow: inset 0 0 0 1px #3D3D3D;
        color: $color-white-CL01;
      }
    }
  }


}

.ant-modal-content {
  .ant-btn-lg {
    padding: 6px 24px;

    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.ant-input-prefix {
  svg {
    color: $neutral-secondary;
  }
}

.ant-form-item-label {
  font-weight: 400;
  padding: 0 !important;

  label {
    color: $color-grey-scale-CL13 !important;
  }
}

.ant-form-item {
  margin-bottom: 12px;

  .ant-form-item-control-input {
    min-height: auto;
  }
}

.ant-form-item-explain {
  &:first-letter {
    text-transform: uppercase;
  }
}

.ant-select {
  .ant-select-arrow {
    inset-inline-end: 9px;
  }
}

.clip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncateDefault {
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: unset;
  overflow: hidden;
}

.truncate {
  @extend .truncateDefault;
  -webkit-line-clamp: 2;
}

.truncate3 {
  @extend .truncateDefault;
  -webkit-line-clamp: 3;
}

.no-space-form-item {
  margin-bottom: 0;
  min-height: 0 !important;
  & div {
    min-height: 0 !important;
  }
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 50%;
}

.ant-btn-icon {
  font-size: 125%;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-user-select {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.hidden-form-item {
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

.ant-pagination {
  align-items: center;

  a {
    border: none;
  }

  .ant-pagination-item-link-icon {
    color: #000 !important;
  }

  li {
    margin-right: 0 !important;
    min-width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;

    &:first-child,
    &:last-child {
      display: flex !important;
      align-items: center !important;
      justify-content: center;

      svg {
        font-size: var(--font-sizeheading6);
      }

      @media (max-width: 360px) {
        display: none !important;
      }
    }

    @media (max-width: 767px) {
      min-width: 36px !important;
      height: 36px !important;
      line-height: 36px !important;

      &:first-child,
      &:last-child {
        svg {
          font-size: var(--font-sizebody1);
        }
      }
    }
  }

  .ant-pagination-item {
    &.ant-pagination-item-active {
      background-color: #ccc;
      border-color: #ccc;

      a {
        color: #000;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: #000;

    &.ant-pagination-disabled {
      color: #00000042;
    }
  }
}

.ant-checkbox-input,
.ant-checkbox .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  width: 24px;
  height: 24px;

  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: #3D3D3D !important;
  border-color: #3D3D3D !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  top: 45%;
  inset-inline-start: 29%;
  width: 5.714286px;
  height: 12.142857px;
  border-width: 1px;

  @media (max-width: 600px) {
    top: 47%;
    inset-inline-start: 28%;
    width: 5.7142857142857135px;
    height: 9.142857142857142px;
  }
}

.ant-collapse {
  border: none;

  .ant-collapse-content {
    border-color: var(--color-line);
    border-top: none;
  }

  .ant-collapse-item {
    .ant-collapse-header {
      padding: 9px 8px !important;
      font-size: var(--font-sizebody2);
      line-height: 20px;
      background-color: #0000000A;
      font-weight: 500;
      border-bottom: solid 1px var(--color-line);
    }

    .ant-collapse-expand-icon {
      svg {
        font-size: 20px !important;
        transition: 0s !important;
        transform: rotate(90deg) !important;
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(270deg) !important;
        }
      }
    }
  }
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #fff;
}

.ant-modal {
  .ant-modal-title {
    font-size: var(--font-sizeheading6);
    line-height: var(--line-heightheading6);
    font-weight: 500;
  }

  .ant-modal-content {
    padding: 32px;
  }

  .ant-modal-header {
    margin-bottom: 16px;
  }

  .ant-modal-close-x {
    font-size: var(--font-sizeheading6);
    color: #000;
  }
}

.ant-collapse-content-box {
  padding: 0 !important;
}

*:not(input):focus-visible,
*:not(textarea):focus-visible {
  outline: none !important;
  //box-shadow: inset 0 0 0 2px #adc6db !important;
}

.ant-select {
  &:not(.ant-select-open):focus-within {
    .ant-select-selector {
      border-color: $primary-color-CL17 !important;
      box-shadow: inset 0 0 0 1px $primary-color-CL17 !important;
    }
  }
}


.ant-image {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    min-width: 32px !important;
    min-height: 32px !important;
    width: auto !important;
    height: auto !important;
  }
}

.ant-image-preview-mask {
  background-color: rgba(0,0,0,.5) !important;
}

button {
  font-size: var(--font-sizebody2) !important;
  font-weight: 500 !important;
  line-height: var(--line-heightbody2) !important;
}

.ant-radio-wrapper {
  .ant-radio {
    margin-right: 4px;
  }

  &:hover {
    .ant-radio-inner {
      border-color: #000 !important;
    }

    .ant-radio {
      position: relative;

      .ant-radio-inner {
        background-color: rgba(0,0,0,0);
      }

      &:before {
        content: '';
        position: absolute;
        left: -33%;
        top: -33%;
        width: 166%;
        height: 166%;
        background-color: rgba(0,0,0,.08);
        border-radius: 50%;
      }
    }
  }

  .ant-radio-input:focus-visible+.ant-radio-inner {
    outline-color: $primary-color-CL17;
    outline-width: 2px;
    outline-offset: 6px;
  }

  &:active {
    .ant-radio {
      position: relative;

      .ant-radio-inner {
        background-color: $color-grey-scale-CL15;
        border-color: $color-grey-scale-CL10 !important;

        &:after {
          background-color: $color-grey-scale-CL12;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: -33%;
        top: -33%;
        width: 166%;
        height: 166%;
        background-color: $color-grey-scale-CL15;
        border-radius: 99%;
      }
    }
  }

  &:focus {
    .ant-radio-inner {
      border-color: #000 !important;
    }

    .ant-radio {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        border: solid 2px $primary-color-CL17;
        box-sizing: border-box;
        left: -33%;
        top: -33%;
        width: 166%;
        height: 166%;
        background-color: rgba(0,0,0,.0);
        border-radius: 99%;
      }
    }
  }
}

.cookies {
  z-index: 9999999;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  min-width: 100vw;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.875rem 70px;
  background-color: #666;

  @media (max-width: 1024px) {
    padding: 1.875rem 48px;
  }

  @media (max-width: 600px) {
    padding: 1.875rem 16px;
  }

  a {
    color: #fff;
    border-bottom-color: #fff;
  }

  > div {
    max-width: 1140px;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      margin-right: 48px;

      @media (max-width: 600px) {
        margin-right: 24px;
      }
    }

    button {
      min-width: 70px;
      border: solid 1px #fff;
      color: #fff !important;
    }
  }
}

.ant-image-preview-img {
  background-color: #fff;
}

.ant-dropdown-button {
  width: auto;

  button {
    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      width: 40px !important;
    }
  }
}
