@import "src/resource/style/variables";

.point {
  font-size: var(--font-sizelabel1);
  line-height: 16px;
  min-height: 20px;
  padding: 2px 2px 2px 18px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 6px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #000;
  }

  &.orange {
    color: $color-accent-colors_CL21;

    &:after {
      background-color: $color-accent-colors_CL21;
    }
  }

  &.green {
    color: $color-signal-CL20;

    &:after {
      background-color: $color-signal-CL20;
    }
  }

  &.red {
    color: $color-signal-CL22;

    &:after {
      background-color: $color-signal-CL22;
    }
  }
}
