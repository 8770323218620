@import "src/resource/style/variables";

a.asset {
  &:hover {
    box-shadow: inset 0 0 0 1px #0A5A96;
  }
}

.asset {
  border: none;
  padding: 8px;
  min-width: 20%;
  color: #000;
  font-size: var(--font-sizebody1);
  min-height: 20px;
  line-height: 20px;

  .wrp {
    color: #000;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;

      img {
        min-width: 32px;
        max-width: 100%;
        max-height: 100%;
      }

      .bg {
        width: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        max-height: 100%;
        height: 100%;
      }
    }

    .type {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $color-grey-scale-CL12;
      border-bottom: solid 1px var(--color-line);

      > * {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .text {
        font-size: var(--font-sizelabel2);
        flex-wrap: wrap;
        line-height: 20px;
        padding: 6px 0;

        > *:not(:last-child) {
          margin-right: 16px;
        }
      }

      .add {
        margin: 0 -8px 0 8px;
        padding: 8px;
        cursor: pointer;

        svg {
          font-size: var(--font-sizebody1);
          color: #000;
        }

        &:hover {
          svg {
            color: $primary-color-CL17;
          }
        }
      }
    }

    .name {
      padding: 6px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .status {
      line-height: 20px;
    }
  }
}
