.content {
  max-width: 1920px;

  .header {
    padding: 0 16px 24px 16px;
    border-bottom: solid 1px var(--color-line);

    .title {
      text-transform: uppercase;
      font-size: 46px;
      line-height: 46px;
      min-height: 60px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
        flex-wrap: wrap;
        gap: 16px;
        padding: 7px 0;
      }

      @media (max-width: 600px) {
        padding-top: 0;
        font-size: 36px;
        line-height: 36px;
      }
    }
  }

  .data {
    display: flex;
    flex-wrap: nowrap;

    .wrp {
      padding: 1px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr minmax(calc(320px - 16px * 2), 566px);
      gap: 24px;

      @media (max-width: 1600px) {
        grid-template-columns: 1fr minmax(calc(320px - 16px * 2), 500px);
      }

      @media (max-width: 1300px) {
        grid-template-columns: 1fr minmax(calc(320px - 16px * 2), 400px);
      }

      @media (max-width: 1024px) {
        grid-template-columns: 1fr minmax(calc(280px - 16px * 2), 280px);
        gap: 16px;
      }

      @media (max-width: 770px) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }

    .detail {
      display: block;
      position: relative;
      top: 0;
      max-width: calc(100vw - 32px);
      margin-bottom: 40px;

      .title {
        position: relative;
        font-size: var(--font-sizeheading4);
        line-height: var(--line-heightheading4);
        font-weight: 700;
        margin-top: 24px;
        padding-bottom: 16px;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          border-bottom: solid 1px var(--color-line);
        }
      }

      .list {
        margin: 24px 0;

        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666;
        }
      }

      button {
        width: 100%;

        svg {
          font-size: var(--font-sizeheading6);
        }
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 16px;
      }

      .expiration {
        margin: 24px 0;
      }
    }
  }
}
