@import 'src/resource/style/variables';

.search {
  max-width: 714px;
  min-width: 360px;
  justify-content: right;
  display: flex;
  flex-wrap: nowrap;
  line-height: 40px;
  font-size: 18px;

  input {
    font-size: 16px !important;
  }

  @media (max-width: 600px) {
    min-width: 300px;

    input {
      font-size: 18px !important;
    }
  }

  @media (max-width: 625px) {
    input {
      line-height: 24px;
    }
  }

  svg {
    font-size: 18px;
    color: #666;
  }

  :global {
    .ant-input-clear-icon {
      align-items: center;
      align-content: center;
      display: flex;
      visibility: visible;
    }

    .ant-input-affix-wrapper {
      border: none;
      box-shadow: none;
      border-bottom: solid 1px #666;
    }
  }

  input {
    width: calc(100vw - 675px);
    min-width: 140px;
    margin-right: 4px;
    transition: 0s !important;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  > div {
    cursor: pointer;
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: var(--font-sizeheading6);
    }
  }
}

form {
  &.search {
    @media (max-width: 767px) {
      &:before {
        z-index: 2;
        content: '';
        position: absolute;
        top: -96px;
        left: 0;
        width: 100%;
        height: 204px;
        background-color: #fff;
        box-shadow: 0 3px 12px 0 #00000033;
      }

      max-width: none;
      top: 96px;
      right: 0;
      height: 48px;
      position: absolute;
      width: 100%;
      border-bottom: solid 1px #666 !important;
      padding: 0 16px;
      justify-content: center;

      > * {
        z-index: 2;
      }

      input {
        border: none !important;
        border-bottom: solid 1px $color-grey-scale-CL10 !important;
        box-shadow: none !important;
        width: calc(100% - 40px);
        position: relative;
        margin-right: 0;
      }

      .submit {
        height: 47px;
        border-bottom: solid 1px $color-grey-scale-CL10 !important;
      }
    }
  }

  .close {
    background-color: #fff;
    z-index: 9;
    position: absolute;
    right: 16px;
    top: -82px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}
