@import "src/resource/style/variables";

.prepareFileArrow,
.prepareFile {
  position: fixed;
  display: block;
  bottom: 16px;
  left: 16px;
  box-shadow: 0 0 10px #939393;
  background-color: #fff;
  border-radius: 3px;

  svg {
    font-size: var(--font-sizeheading6);
  }
}

.prepareFileArrow {
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  z-index: 1;

  > * {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.done {
  svg {
    color: #008800;
  }
}

.close,
.open {
  cursor: pointer;
}

.prepareFile {
  width: 100%;
  max-width: calc(320px - 16px * 2);
  max-height: calc(440px);
  z-index: 1;

  .head {
    padding: 0 8px 0 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    line-height: var(--line-heightheading6);
    font-weight: 500;
    border-bottom: solid 1px var(--color-line);
    font-size: 16px;
    text-wrap: nowrap;

    .close {
      font-size: var(--font-sizebody1);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 100%;
    max-height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;

    .wrp {
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 25px;
      line-height: 25px;
      flex-wrap: wrap;
      padding-bottom: 8px;

      &:not(:last-child) {
        border-bottom: solid 1px var(--color-line);
      }
    }

    .name {
      align-items: start;
      width: calc(100% - 60px);
      max-width: calc(100% - 60px);
      max-height: 50px;
      display: flex;
      overflow: hidden;
    }

    .success {

    }

    .actions {
      padding: 8px 0;
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 16px;
    }

    .counter {
      max-width: 60px;
      min-width: 60px;
      width: 60px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: end;
      align-items: center;
      position: relative;

      > *:first-child {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: start;
        left: 3px;
        width: 100%;
        height: 100%;

        * {
          font-size: 25px;
        }
      }

      .split {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        font-size: var(--font-sizebody2);
      }

      .current,
      .total {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 100%;
      }
    }
  }
}
