@import 'src/resource/style/variables';

:global {
  .ant-layout-header {
    height: 72px;
  }
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 24px;
  margin: 0;
  line-height: 100%;

  @media (max-width: 767px) {
    padding: 14px 16px 14px 0;
  }

  :global {
    a {
      color: #000;

      svg {
        font-size: var(--font-sizeheading6);
      }

      &:hover {
        color: $primary-color-CL17;
      }

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }

      &.active {
        color: $primary-color-CL17;
      }
    }
  }

  > input {
    justify-self: right;
    height: 40px;
    width: 100px;
  }

  .item {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: right;

    &:not(:last-child) {
      margin-right: 8px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      text-decoration: none;
      border: none;

      svg {
        font-size: var(--font-sizeheading6);
      }
    }
  }
}
