@import "src/resource/style/variables";

.footer {
  display: flex;
  justify-content: space-between;
  padding: 84px 24px 50px;
  box-sizing: border-box;
  position: relative;

  a {
    &:active,
    &:focus {
      border-top-color: rgba(0, 0, 0, 0) !important;
      border-left-color: rgba(0, 0, 0, 0) !important;
      border-right-color: rgba(0, 0, 0, 0) !important;
      box-shadow: none !important;
    }
  }

  @media (max-width: 880px) {
    padding-top: 76px;
    flex-wrap: wrap;
  }

  .goto {
    @media (max-width: 880px) {
      margin: 0 32px 46px 0;
    }

    a {
      border: none !important;

      &:hover {
        border-bottom: solid 1px $primary-color-CL17 !important;
      }
    }
  }

  b {
    font-size: var(--font-sizebody2);
    font-weight: 500;
    line-height: 20px;
    color: $color-black-CL16;
  }

  .menu {
    > *:not(:last-child) {
      margin-right: 16px;
    }

    span {
      color: $color-grey-scale-CL14;
    }

    a {
      border: solid 1px #fff;
      transition: .1s;

      &:hover {
        border-bottom: solid 1px #000;
      }
    }

    &.showMobile {
      display: none;
    }

    @media (max-width: 880px) {
      display: grid;
      grid-gap: 16px;

      &.hideMobile {
        display: none;
      }

      &.showMobile {
        display: grid;
      }
    }
  }
}
