@import '../../../../resource/style/variables';

.content {
  max-width: 1920px;

  .header {
    padding: 24px 16px;
    border-bottom: solid 1px var(--color-line);

    @media (max-width: 600px) {
      padding: 16px 0;
    }

    .counter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--font-sizelabel1);
      color: $color-grey-scale-CL12;


      @media (max-width: 620px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        align-items: start;
        align-content: start;
        justify-content: end;
        gap: 24px;

        > * {
          width: auto;
        }

        @media (max-width: 620px) {
          padding-top: 0;
          font-size: 36px;
          line-height: 36px;
          justify-content: center;
        }

        @media (max-width: 1024px) {
          gap: 16px;
        }

        @media (max-width: 767px) {
          margin-left: 0;
          flex-wrap: wrap;
          gap: 8px;

          > * {
            @media (max-width: 600px) {
              width: 137px;

              @media (max-width: 465px) {
                width: 100% !important;

                > button:first-child {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .title {
      text-transform: uppercase;
      font-size: 46px;
      line-height: 46px;
      min-height: 60px;
      font-weight: 700;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow: hidden;
      justify-content: space-between;
      max-height: 278px;

      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 0 7px 0;
        max-height: 162px;
      }

      @media (max-width: 600px) {
        padding-top: 0;
        font-size: 36px;
        line-height: 36px;
        display: grid;
        grid-template-columns: 1fr;
        max-height: 185px;
      }
    }

    .description {
      max-height: 200px;
      overflow: hidden;
      margin-bottom: 8px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0 0 32px;

    @media (max-width: 600px) {
      padding: 16px 0 32px;
    }

    .wrp {
      display: grid;
      grid-template-columns: 60px 1fr;

      @media (max-width: 1023px) {
        grid-template-columns: 50px 1fr;
      }

      @media (max-width: 600px) {
        grid-template-columns: 30px 1fr;
      }

      > *:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .item {
      border: none !important;
    }

    > * {
      width: 100%;
    }
  }
}
