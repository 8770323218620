@import "../../../../../resource/style/variables";

.modalCreate {
  .content {
    padding: 8px 0 32px;

    form {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
