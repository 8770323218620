@import '../../../../../resource/style/variables';

.checkboxCounter {
  display: flex;
  align-items: center;
  padding-left: calc((60px - 24px) / 2);
  height: 40px;
  margin-top: 24px;
  margin-bottom: 16px;

  @media (max-width: 1023px) {
    padding-left: calc((50px - 24px) / 2);
  }

  @media (max-width: 600px) {
    margin-top: 16px;
    margin-bottom: 8px;
    padding-left: calc((40px - 24px) / 2);
  }

  > *:first-child {
    margin-right: 12px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 0 32px;

  @media (max-width: 600px) {
    padding: 16px 0 32px;
  }

  .wrp {
    display: grid;
    grid-template-columns: 60px 1fr;

    &.hidden {
      background-color: rgba(0,0,0,.1);
      opacity: .5;
      pointer-events: none;

      *,
      *:hover {
        border-color: rgba(0,0,0,0) !important;
        box-shadow: none !important;
      }
    }

    @media (max-width: 1023px) {
      grid-template-columns: 50px 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 30px 1fr;
    }

    > *:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .item {
    border: none !important;
  }

  > * {
    width: 100%;
  }
}
