@import 'src/resource/style/variables';

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .asset {
    width: 100%;
    position: relative;
    max-width: calc(100vw - 33px);

    .row {
      display: flex;
      flex-wrap: nowrap;
      padding: 16px;
      gap: 16px;

      @media (max-width: 770px) {
        padding: 0;
      }
    }

    .img {
      width: 100%;
      background-position: top center;
      background-size: contain;
      background-repeat: no-repeat;
      max-height: 100%;
      height: 100%;
      min-width: 131px;
      max-width: 131px;

      @media (max-width: 1024px) {
        min-width: 120px;
        max-width: 120px;
      }

      @media (max-width: 900px) {
        min-width: 100px;
        max-width: 100px;
      }

      @media (max-width: 770px) {
        min-width: 150px;
        max-width: 150px;
        min-height: 150px;
        max-height: 150px;
      }

      @media (max-width: 500px) {
        min-width: 131px;
        max-width: 131px;
        min-height: 131px;
        max-height: 131px;
      }

      @media (max-width: 400px) {
        min-width: 110px;
        max-width: 110px;
        min-height: 110px;
        max-height: 110px;
      }
    }

    .remove {
      position: absolute;
      right: 0;
      top: 14px;
      font-size: var(--font-sizebody1);
      cursor: pointer;
      width: 30px;
      height: 30px;
      padding: calc((30px - 16px) / 2);
      background: #fff;

      @media (max-width: 770px) {
        top: 0;
      }
    }

    .info {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;

      .name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 90px;
        font-size: var(--font-sizeheading6);
        font-weight: 500;
        line-height: var(--line-heightheading6);
        margin-bottom: 8px;

        @media (max-width: 770px) {
          padding-right: 30px;
        }
      }

      .line {
        width: 100%;
        border-bottom: solid 1px var(--color-line);
        margin: 8px 0;
      }

      .type {
        font-size: var(--font-sizelabel2);
        font-weight: 400;
        line-height: var(--line-heightlabel2);
        color: $color-grey-scale-CL12;
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;

        @media (max-width: 1024px) {
          gap: 16px;
        }
      }

      .fileInfo {
        font-size: var(--font-sizelabel2);
        line-height: var(--line-heightlabel2);
        color: $color-grey-scale-CL12;

        > *:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    .size {
      > span {
        color: $color-grey-scale-CL12;
        padding-bottom: 4px;
      }

      .select {
        width: 100%;
      }
    }
  }
}
