.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .pretty {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    max-width: 100vw;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    pointer-events: none;
  }

  .absolute {
    background: rgba(255, 255, 255, 0.55);
    position: absolute !important;
    align-items: start;
    padding-top: 100%;
    max-height: 100%;
    box-sizing: border-box;
    pointer-events: auto;
  }

  svg {
    color: #000;
  }
}

