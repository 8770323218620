.notFound {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .link {
    margin-top: 24px;
    display: flex;
    align-items: center;
    line-height: 40px;
    border: none;
    box-shadow: none !important;
    height: 40px;
    width: 100px;

    svg {
      margin-right: 12px;
      font-size: var(--font-sizeheading6);
    }
  }
}
