@import "src/resource/style/variables";

.breadcrumbs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  min-height: 40px;
  width: 100%;
  border-bottom: solid 1px var(--color-line);

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  a {
    text-decoration: none;
    border-bottom: none;
    color: $color-grey-scale-CL12;

    &[href] {
      font-weight: 500;
    }
  }

  > * {
    color: $color-grey-scale-CL12;
    font-size: var(--font-sizebody2);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      font-size: var(--font-sizebody1);
    }
  }

  .item {
    > svg {
      min-width: 16px;
      width: 16px;
      font-size: var(--font-sizebody1);
    }

    > * {
      height: 22px;
      min-height: 22px;
      max-height: 22px;
      margin: 9px 0;
      display: flex;
      align-items: start;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    .name {
      max-width: calc(100vw - 16px * 2);
      overflow: hidden;
      padding: 0 6px 1px;
      border: none !important;

      &:not([href]) {
        cursor: default;
      }
    }
  }
}
