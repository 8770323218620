@import "src/resource/style/variables";

.keywords {
  margin-bottom: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }

  .name {
    font-weight: 400;
    font-size: var(--font-sizeheading6);
    line-height: var(--line-heightheading6);
    color: $color-grey-scale-CL12;
    margin-bottom: 8px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    .item {
      cursor: pointer;
      height: 24px;
      padding: 0 8px;
      background-color: #0000001A;
      border: solid 1px #00000042;
    }
  }
}
