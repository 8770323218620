@import "src/resource/style/variables";

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
  background: $color-white-CL01;
  position: relative;
}
