@import '../../../../../resource/style/variables';

.item {
  display: grid;
  flex-wrap: nowrap;
  padding: 16px;
  gap: 16px;
  cursor: pointer;
  color: #000;
  border: none;
  overflow: hidden;
  position: relative;

  @media (max-width: 600px) {
    padding: 8px;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px #0A5A96;
    color: #000;
  }

  .img {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    max-height: 400px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .data {
    overflow: hidden;
    font-size: var(--font-sizelabel2);
    line-height: var(--line-heightlabel2);
    color: $color-grey-scale-CL12;

    .name {
      color: #000;
      font-size: var(--font-sizeheading6);
      font-weight: 500;
      line-height: var(--line-heightheading6);
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      min-height: 28px;
      max-height: 56px;
      margin-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 600px) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        gap: 4px;
        max-height: 100%;
        font-size: var(--font-sizebody1);
        line-height: var(--line-heightbody1);
      }

      .text {
        width: calc(100% - 100px);
        overflow: hidden;

        &.stretch {
          width: 100%;
        }

        @media (max-width: 600px) {
          width: 100%;
          max-height: 112px;
          overflow: hidden;
        }
      }

      .actions {
        margin-left: 8px;
        display: flex;
        flex-wrap: nowrap;
        font-size: var(--font-sizebody1);
        gap: 8px;

        @media (max-width: 600px) {
          margin-left: 0;
          width: 100%;
          justify-content: end;
        }

        svg {
          width: 16px;
          height: 16px;
          color: #000;
        }

        > * {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          position: relative;

          &:hover {
            svg {
              color: #376EB4;
            }
          }
        }
      }
    }

    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 66px;
      padding-bottom: 8px;
    }
  }

  .line {
    font-size: var(--font-sizelabel1);
    line-height: var(--line-heightlabel1);
    margin-top: 8px;

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      width: 100%;
      height: 0;
      border-top: solid 1px var(--color-line);
    }
  }

  > .line {
    display: none;
  }

  @media (max-width: 600px) {
    gap: 8px;

    > .img {
      grid-area: img;
    }

    > .data {
      grid-area: data;
    }

    > .line {
      grid-area: line;
    }

    grid-template-columns: none;
    grid-template-areas:
      "img  data"
      "line line";

    .data {
      > .line {
        display: none;
      }
    }

    > .line {
      display: flex;
    }
  }
}
