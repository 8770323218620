@import "../../../../../../resource/style/variables";

.modalDelete {
  .content {
    padding: 8px 0 32px;

    .name {
      font-size: var(--font-sizebody1);
    }

    form {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
