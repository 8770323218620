@import "../../../../../resource/style/variables";

.modalCollection {
  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    overflow-y: auto;
    max-height: 60vh;
    overflow-x: hidden;
    margin: 0 -22px -6px;
    padding-bottom: 6px;

    .new,
    .item {
      padding: 8px;
      display: grid;
      grid-template-columns: 86px 1fr;
    }

    .item {
      position: relative;
      grid-gap: 16px;

      .btn {
        position: absolute;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        right: 16px;
        top: calc(50% - 16px);
        display: none;
        width: 70px;

        > button {
          max-width: 90px;
        }

        > span {
          color: red;
          font-size: 10px;
        }
      }

      &:hover {
        background-color: #0000000A;

        .btn {
          display: flex;
        }
      }

      .name,
      .description {
        width: calc(100% - 80px);
      }

      .img {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        aspect-ratio: 1/1 !important;
      }

      .info {
        overflow: hidden;
        padding-right: 8px;
        font-size: var(--font-sizelabel1);
        line-height: var(--line-heightlabel1);
        color: $color-grey-scale-CL12;
        gap: 8px;
        display: flex;
        flex-wrap: wrap;

        .name {
          color: #000;
          overflow: hidden;
          max-height: 28px;
          font-size: var(--font-sizeheading6);
          line-height: var(--line-heightheading6);
          font-weight: 500;
          max-width: 100%;
        }

        .description {
          overflow: hidden;
          max-height: 32px;
        }

        .counter {
          width: 100%;
        }
      }
    }

    .new {
      border: solid 1px $color-grey-scale-CL10;
      grid-gap: 8px;
      margin-top: 16px;

      form {
        > *:last-child {
          font-size: var(--font-sizelabel1);
          line-height: var(--line-heightlabel1);
          color: $color-grey-scale-CL12;
          margin-left: 4px;
        }
      }

      > svg {
        font-size: 86px;
      }

      .info {
        width: 100%;

        > * {
          width: 100%;
          margin-bottom: 0;
        }

        input,
        textarea {
          border: none;
          outline: none;
          width: 100%;
          resize: none;
          padding: 4px 4px;
          box-sizing: border-box;
          color: #000;
        }

        input {
          font-size: var(--font-sizeheading6);
          line-height: var(--line-heightheading6);
          height: 28px;
          min-height: 36px;
          max-height: 36px;
        }

        textarea {
          font-size: var(--font-sizebody1);
          line-height: var(--line-heightbody1);
          min-height: 24px;
        }
      }
    }
  }

  .noContent {
    font-size: var(--font-sizebody2);
    line-height: 20px;
    font-weight: 400;
    color: $color-grey-scale-CL10;
  }

  .btnCenter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global {
    .ant-btn.ant-btn-text {
      color: $primary-color-CL17 !important;

      &:hover {
        background-color: rgba(0,0,0,0.08);
      }

      &:focus {
        border-color: $primary-color-CL17;
        box-shadow: inset 0 0 0 1px $primary-color-CL17;
      }

      &:active {
        background-color: #3D3D3D;
        border-color: #3D3D3D;
        box-shadow: inset 0 0 0 1px #3D3D3D;
        color: $color-white-CL01 !important;;
      }
    }
  }
}
