@font-face {
  font-display: swap;
  font-family: AktivGrotesk;
  font-style: normal;
  font-weight: 700;
  src: local("Aktiv Grotesk Bold"),url(AktivGroteskGeberit_W_Bd.woff2) format("woff2"),url(AktivGroteskGeberit_W_Bd.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: AktivGrotesk;
  font-style: normal;
  font-weight: 500;
  src: local("Aktiv Grotesk Medium"),url(AktivGroteskGeberit_W_Md.woff2) format("woff2"),url(AktivGroteskGeberit_W_Md.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: AktivGrotesk;
  font-style: normal;
  font-weight: 400;
  src: local("Aktiv Grotesk Regular"),url(AktivGroteskGeberit_W_Rg.woff2) format("woff2"),url(AktivGroteskGeberit_W_Rg.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: AktivGrotesk;
  font-style: normal;
  font-weight: 300;
  src: local("Aktiv Grotesk Light"),url(AktivGroteskGeberit_W_Lt.woff2) format("woff2"),url(AktivGroteskGeberit_W_Lt.woff) format("woff")
}

:root {
  --fontglobal: "AktivGrotesk",Helvetica,Arial;
}
