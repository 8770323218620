@import 'src/resource/style/variables';

.user {
  display: flex;
  flex-wrap: nowrap;
  line-height: 40px;

  .menu {
    z-index: 2;

    svg {
      display: none;
    }
  }


  &.active .link,
  &:hover,
  .menu {
    background-color: #F1F1F1;
  }

  .menu {
    position: absolute;
    top: 40px;
    right: 0;
    box-shadow: 0 3px 12px 0 #00000033;

    > * {
      display: flex;
      justify-content: flex-start !important;
      padding: 0 16px;
      text-align: left;
      width: 100%;

      &:hover {
        background-color: #ccc;
      }
    }
  }

  .close {
    display: none;
  }

  @media (min-width: 767px) {
    position: relative;

    .menu {
      .lang {
        display: none;
      }
    }
  }

  .line {
    display: none;
  }

  @media (max-width: 767px) {
    &.active:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 150px;
      background: #fff;
      z-index: 2;
      box-shadow: 0 3px 12px 0 #00000033;
    }

    .line {
      display: block;
      width: 100%;
      border-bottom: solid 1px var(--color-line);
      margin: 16px 0;
    }

    .menu {
      box-shadow: none;
      width: 100%;
      top: 72px;
      padding: 16px;
      font-weight: 500;

      > * {
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          display: block;
          font-size: var(--font-sizeheading6);

          &:first-child {
            margin-right: 12px;
          }
        }

        &:last-child {
          padding: 0;
        }
      }

      .lang {
        padding: 0 10px;
        display: flex;
        justify-content: space-between !important;
        width: 100%;

        svg {
          margin-right: 0;
        }
      }
    }

    .close {
      position: absolute;
      display: block;
      right: 16px;
      top: 12px;
      width: 40px;
      height: 40px;
      padding: 10px;
      cursor: pointer;
      background: #fff;
      z-index: 3;
    }
  }
}
