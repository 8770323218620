@import "../../../../../resource/style/variables";

.modalDownload {
  .content {
    padding: 16px 0 32px;

    .name {
      font-size: var(--font-sizebody1);
    }
  }
}
