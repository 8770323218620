@import "src/resource/style/variables";

.gallery {
  max-width: calc(100vw - 16px * 2);
  position: relative;
  display: flex;
  align-items: flex-start;

  :global {
    .ant-image-img {
      min-width: 100%;
      max-width: 100%;
      max-height: 80vh;
      width: 100%;
    }
  }

  .video {
    position: relative;

    max-width: 80vw;
  }

  &:hover {
    .zoom {
      color: #376EB4;
    }
  }
}

.iframe {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  border: none;
  outline: none;
}

.zoom {
  color: #fff;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  bottom: 16px;
  pointer-events: none;

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
  }
}

.video {
  max-width: 90vw;
  max-height: 90vh;
}
