.moveToTop {
  cursor: pointer;
  left: calc(100vw - 48px - 24px);
  width: 48px;
  height: 48px;
  background-color: #000000CC;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  z-index: 9;

  &.fixed {
    left: calc(100vw - 48px - 24px);
    position: fixed;
  }

  @media (max-width: 600px) {
    left: calc(100vw - 48px - 20px);
    bottom: 40px;

    &.fixed {
      left: calc(100vw - 48px - 20px);
      position: fixed;
    }
  }
}
