@import "../../../../../resource/style/variables";

.modalDownload {
  .content {
    padding: 8px 0 32px;

    .list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 16px;

      .item {
        width: 100%;
      }
    }

    .name {
      font-size: var(--font-sizebody1);
    }

    form {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
