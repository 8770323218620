@import "src/resource/style/variables";

.main,
.collapse {
  margin: 24px 0;

  @media (max-width: 767px) {
    margin: 16px 0;
  }
}

.collapse {
  margin-bottom: 48px;
}

.collapse > * {
  margin: 8px 0;
}

.wrapper {
  max-width: 1920px;
}

.main {
  display: grid;
  grid-template-columns: minmax(600px, 1fr) 1.6fr;
  grid-gap: 24px;

  @media (max-width: 1600px) {
    grid-template-columns: minmax(500px, 1fr) 1.5fr;
  }

  @media (max-width: 1439px) {
    grid-template-columns: minmax(400px, 1fr) 1.4fr;
  }

  @media (max-width: 1023px) {
    grid-template-columns: minmax(300px, 1fr) 1.25fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
